  <template>
    <div class="h5 uf uf-ac uf-pc" style="height: 100vh;">
        <div class="login-box p-5">
            <div class="logo-box uf uf-pc">
                <div class="wrap uf uf-ac uf-pc wi100" style="cursor: pointer">
                    <img src="~@/assets/img/logo-sp.png" alt="">
                </div>
            </div>
            <div class="tac f18 fwb">欢迎来到数据安全共享平台</div>
            <el-form class="mt-4" :model="dataForm" :rules="dataRule1" ref="dataForm" @keyup.enter.native="doSub()"
                     status-icon>
                <el-form-item prop="userName">
                    <el-input prefix-icon="el-icon-user" v-model="dataForm.userName" placeholder="请输入用户名" clearable></el-input>
                </el-form-item>
                <el-form-item prop="password" >
                    <el-input prefix-icon="el-icon-lock" v-model="dataForm.password" type="password" placeholder="密码"
                              clearable></el-input>
                </el-form-item>
                <el-button type="primary" class="wi100" @click="doSub" :loading="loading">登录</el-button>
                <div class="uf uf-ac uf-pj mt-3">
                    <div class="c-base cp f14" @click="$router.push({ name: 'register', params:{ h5: 'true'}})">没有账号？去注册</div>
                    <div class="c-base cp f14" @click="$router.replace({ name: 'forgetPassword', params:{ h5: 'true'}})">忘记密码？</div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {getUUID, encode64, checkIsNull} from '@/utils'
    import {isMobile} from '../../utils/validate'
    export default {
        name: 'login',
        data () {
            return {
                loginType: 'normal',
                dataForm: {
                    userName: '',
                    password: '',
                    uuid: ''
                },
                captchaPath: '',
                dataRule1: {
                    userName: [
                        {required: true, message: '帐号不能为空', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '密码不能为空', trigger: 'blur'}
                    ]
                },
                loading: false,
                smsSuc: false,
                smsTime: '',
                timer: null,
                openId: ''
            }
        },
        created () {
            this.openId = this.$route.params.openid || ''
        },
        computed: {
            winWidth :{
                get () { return  document.documentElement.clientWidth }
            },
        },
        methods: {
            doSub () {
                let _this = this
                this.dataForm.uuid = getUUID()
                this.$refs['dataForm'].validate((valid) => {
                    if (valid && !this.loading) {
                        let userSource = this.$cookie.get('userSource')
                        if (checkIsNull(userSource)) {
                            this.$cookie.set('userSource', getUUID())
                        }
                        this.loading = true
                        this.$http({
                            url: _this.$http.adornUrl(this.$api.QUALITY_DOCTOR.WECHAT_LOGIN),
                            method: 'post',
                            data: _this.$http.adornData({
                                'username': _this.dataForm.userName,
                                'uuid': _this.dataForm.uuid,
                                'password': encode64(this.$encryptECB(_this.dataForm.password)),
                                'openid': this.openId,
                                'accountType': 'USER'
                            })
                        }).then(({data}) => {
                            if (data && data.code === 0) {
                                this.$message.success('登录成功')
                                this.$cookie.set('marketToken', data.token)
                                setTimeout(function () {
                                    _this.$router.push({name: 'service-module'})
                                }, 1000)
                            } else {
                                this.$message.error(data.msg)
                                this.$nextTick(() => {
                                    this.loading = false
                                })
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    >>> .chatBtn {
        display: none !important;
    }
    .h5 {
        background-image: linear-gradient(50deg, #007bff, #51f9fb)
    }
    .login-box {
        margin: 0 1rem;
    }
</style>
